import React from 'react'
import dynamic from 'next/dynamic'
import styles from '../../styles/Home.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import Integrations from '../../../public/gif/Integrations_Animation.gif'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Image from 'next/image'

const Button = dynamic(() => import('@/components/Common/WhiteButton'))

const IntegrationCard = () => {
  return (
    <Container>
      <Row style={{ marginBlock: 50 }}>
        <Col className={styles.homeIntegrationTitle} md={6}>
          <h2 style={{ fontFamily: 'EnzoLight', fontWeight: 'bold', letterSpacing: 0.2 }}>Streamline your operations with </h2>
          <h2>seamless Integrations</h2>
          <p>
            Get more out of your 40+ go-to tools.
            <br /> Tailor your workflows to fit your unique needs.
          </p>
          <div style={{ display: 'flex', flexDirection: 'row', marginBlock: 40 }}>
            <Button blue text={'WebHR Integrations'} link={'/integrations'} />
          </div>
        </Col>
        <Col className={styles.IntegrationImage} md={6}>
            <video  src='https://cdn.webhr.co/Website/images/landing_page/Integrations_Animation.webm' autoPlay muted loop style={{width:"100%"}} />
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(IntegrationCard)
